<template>
  <div>
    <form-require-noti />
    <v-row>
      <v-col lg="3">
          <label>Patient's Township <require-sign /></label>
                <v-select
                :items="townships"
                v-model="homeVisitEachData.patient_township_id"
                item-text="name"
                item-value="id"
                dense
                outlined
                ></v-select>
        </v-col>
      <v-col lg="3">
        <label>Code</label>
        <small class="text-muted">&nbsp; (eg. 99 or 2)</small>
        <v-text-field
          v-model="homeVisitEachData.mdrtb_code"
          hide-details
          type="number"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col lg="3">
        <label>Year</label>
        <small class="text-muted">&nbsp; (eg. 24)</small>
        <v-text-field
          v-model="homeVisitEachData.mdrtb_year"
          hide-details
          type="number"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col lg="3">
        <label>Mono-Poly/SG<require-sign /></label>
        <v-select
          v-model="homeVisitEachData.monopoly"
          :items="[
            { id: 1, text: 'No' },
            { id: 2, text: 'Yes' },
            { id: 3, text: 'SG' },
          ]"
          :error="$v.homeVisitEachData.monopoly.$error"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3">
        <label>Type</label>
        <v-select
          :items="[
            { id: 1, text: 'In person' },
            { id: 2, text: 'By phone' },
          ]"
          v-model="homeVisitEachData.type"
          :error="$v.homeVisitEachData.type.$error"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col lg="3">
        <label>Outside Township</label>
        <v-select
          :items="[
            { id: '1', text: 'No' },
            { id: '2', text: 'Yes' },
          ]"
          v-model="homeVisitEachData.outside_township"
          :error="$v.homeVisitEachData.outside_township.$error"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col lg="3">
        <label>Date</label>
        <date-picker
          @clearDate="homeVisitEachData.date = null"
          :has_error="$v.homeVisitEachData.date.$error"
          :date="homeVisitEachData.date"
          v-model="homeVisitEachData.date"
          :min="minDate"
          :max="maxDate"
        />
      </v-col>
    </v-row>
    <v-simple-table dense>
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th>No</th>
            <th>Questions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="infection in homeVisitEachData.infection_control.slice(0, 9)"
            :key="infection.id"
          >
            <td>{{ infection.id }}</td>
            <td width="70%">{{ showInfectionText(infection.id) }}</td>
            <td>
              <v-radio-group
                class="my-2"
                v-model="infection[Object.keys(infection)[1]]"
                hide-details
                dense
                row
              >
                <v-radio label="Yes" value="2"></v-radio>
                <v-radio label="No" value="1"></v-radio>
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- HOUSE HOLD SECTION -->
    <v-row>
      <v-col lg="9">
        <p>How many people live with the patient in the same household ?</p>
      </v-col>
      <v-col lg="3">
        <v-text-field
          v-model="homeVisitEachData.lived_patients_no"
          :error="$v.homeVisitEachData.lived_patients_no.$error"
          placeholder="type number only....."
          hide-details
          type="number"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-simple-table dense>
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th>No</th>
            <th>Questions - 10</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(infection,
            index) in homeVisitEachData.infection_control.slice(14, 19)"
            :key="index"
          >
            <td>10.{{ index + 1 }}</td>
            <td width="70%">{{ showInfectionText(infection.id) }}</td>
            <td>
              <v-text-field
                class="my-2"
                v-model="infection[Object.keys(infection)[1]]"
                placeholder="type number only....."
                hide-details
                type="number"
                outlined
                dense
              ></v-text-field>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- AVOID SECTION -->
    <v-divider />
    <p class="mt-4">
      How does the patient reduce transmission and exposure to others while
      he/she is still contagious?
    </p>
    <v-simple-table dense>
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th>No</th>
            <th>Questions - 11</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(infection,
            index) in homeVisitEachData.infection_control.slice(9, 14)"
            :key="index"
          >
            <td>11.{{ index + 1 }}</td>
            <td width="70%">{{ showInfectionText(infection.id) }}</td>
            <td>
              <v-radio-group
                class="my-2"
                hide-details
                v-model="infection[Object.keys(infection)[1]]"
                dense
                row
              >
                <v-radio label="Yes" value="2"></v-radio>
                <v-radio label="No" value="1"></v-radio>
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

 
   
    <v-col lg="10">
      <label>Remark</label>
      <v-text-field v-model="homeVisitEachData.infection_remark"></v-text-field>
    </v-col>
    <div class="d-flex justify-end">
      <v-btn outlined color="teal accent-4" dark @click="setInfectionValue">
        Next
        <v-icon right>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { ValidationMixin } from "../../mixins/ValidationMixin";
import { limitDateMixin } from "../../mixins/limitDateMixin";
import { mapState,mapActions } from "vuex";
import FormRequireNoti from "@/components/notification/FormRequireNoti.vue";
import DatePicker from "@/components/common/DatePicker.vue";


export default {
  components: {
    FormRequireNoti,
    DatePicker,
  },
  mixins: [ValidationMixin, limitDateMixin],
  props: {
    month_year: {
      type: Object,
    },
    homeVisitEachData: {
      type: Object,
      default: () => {
        return {
          type: null,
          patient_id:null,
          date: null,
          patient_township_id:null,
          infection_remark: "",
          sideeffect_remark: "",
          id: null,
          lived_patients_no: null,
          mdrtb_code: null,
          mdrtb_year: null,
          //! this array will add as a database new column //
          infection_control: [
            { id: 1, value_1: "1" },
            { id: 2, value_2: "1" },
            { id: 3, value_3: "1" },
            { id: 4, value_4: "1" },
            { id: 5, value_5: "1" },
            { id: 6, value_6: "1" },
            { id: 8, value_8: "1" },
            { id: 9, value_9: "1" },
            { id: 10, value_9: "1" },
            //* QUESTION 7
            { id: 11, value_10: "1" },
            { id: 12, value_11: "1" },
            { id: 13, value_12: "1" },
            { id: 14, value_13: "1" },
            { id: 15, value_14: "1" },
            //* QUESTION 3
            { id: 16, value_10: 0 },
            { id: 17, value_11: 0 },
            { id: 18, value_12: 0 },
            { id: 19, value_13: 0 },
          ],
        };
      },
    },
  },
  data: () => ({
    infectionText: [
      { id: 1, text: "Is the patient taking the drugs regularly?" },
      { id: 2, text: "Is the patient tolerating the treatment?" },
      { id: 3, text: "Does the patient practice good cough hygiene?" },
      { id: 4, text: "Wearing of face mask" },
      {
        id: 5,
        text: "Does the patient know how to dispose the sputum safely?",
      },
      { id: 6, text: "Does the patient has means to do it?" },
      { id: 8, text: "Opening of windows for good ventilation if possible" },
      {
        id: 9,
        text:
          "If feasible, isolation of patient at least during the sleeping hours",
      },
      { id: 10, text: "Examination of sputum according to guideline" },

      { id: 11, text: "Avoiding crowded places" },
      {
        id: 12,
        text:
          "Avoiding close contact with children, HIV patients, and elderly people",
      },
      { id: 13, text: "Reducing the visitors to patient home to minimum" },
      { id: 14, text: "Avoid travelling during treatment" },
      {
        id: 15,
        text:
          "Cleaning of patient’s room with wet mob and soap powder or disinfectant ",
      },

      { id: 16, text: "children under 5 year" },
      { id: 17, text: "pregnant woman" },
      { id: 18, text: "HIV positive patient" },
      { id: 19, text: "Diabetic patient" },
    ],
  }),
  validations: {
    homeVisitEachData: {
      mdrtb_code: { required },
      mdrtb_year: { required },
      monopoly:{required},
      lived_patients_no: { required },
      type: { required },
      date: { required },
      outside_township: { required },
    },
  },
  methods: {
    ...mapActions("InfoData", ["getAllTownships"]),
    showInfectionText(id) {
      let infectionObj = this.infectionText.find((e) => e.id == id);
      return infectionObj.text;
    },
    setInfectionValue() {
      //toast validation mixin function
      this.checkToastValidationMsg(this.$v.homeVisitEachData);
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = {
          patient_id:this.patient_id,
          township_id: this.admin.township_id,
          month: this.month_year.month,
          year: this.month_year.year,
          mdrtb_code: this.homeVisitEachData.mdrtb_code,
          mdrtb_year: parseInt(this.homeVisitEachData.mdrtb_year),
          monopoly:this.homeVisitEachData.monopoly==null?1:this.homeVisitEachData.monopoly,
          type: this.homeVisitEachData.type,
          date: this.homeVisitEachData.date,
          infection_remark: this.homeVisitEachData.infection_remark,
          outside_township: this.homeVisitEachData.outside_township,
          lived_patients_no: this.homeVisitEachData.lived_patients_no,
          infection_control: this.homeVisitEachData.infection_control,
          patient_township_id:this.homeVisitEachData.patient_township_id,
        };

        
        if (this.homeVisitEachData.id) data.id = this.homeVisitEachData.id;

       // const threeDigitPattern = /^[0-9]{3}$/;
        const twoDigitPattern = /^.*[0-9]{2}$/;

        if (
          twoDigitPattern.test(this.homeVisitEachData.mdrtb_year)
        ) {
           this.$store
                .dispatch("HomeVisit/checkCode", data)
                .then((response) => {
               
                  if(response!=false)
                  {
                    this.$toast.success("Success");
                    data.patient_id=response.data.data.id;
                    data.treatment_startdate=response.data.data.treatment_startdate;
                    data.infectionDate=this.homeVisitEachData.date;
                   
                  
                    this.$store.dispatch("HomeVisit/setInfectionAlldata", data);
                    //this.$emit("nextSideEffect");
                    this.$emit("nextSideEffect",data);

                  }else{
                    this.$toast.error("Invalid code or year");
                    
                  }
            });
         
        } else {
          // console.log(threeDigitPattern.test(this.homeVisitEachData.mdrtb_code));
          // if (!threeDigitPattern.test(this.homeVisitEachData.mdrtb_code))
          //   this.$toast.error(
          //     "code must has at least three digit."
          //   );
          if (!twoDigitPattern.test(this.homeVisitEachData.mdrtb_year))
            this.$toast.error("year must has at least two digit.");
          this.clearToastErrors();
        }
      } else {
        //if validation has errors  display with toast
        //then clear the errors
        this.toastErrors.forEach((element) => {
          this.$toast.error(element);
        });
        this.clearToastErrors();
      }
    },
  },
  computed: {
    ...mapState("InitialData", ["months", "years"]),
    ...mapState("Admin", ["admin"]),
    ...mapState("InfoData", [ "townships"]),
  },
  created() {
    this.getAllTownships();
    this.homeVisitEachData.patient_township_id=!this.homeVisitEachData.patient_township_id? Number( this.admin.township_id):this.homeVisitEachData.patient_township_id;

  },
  beforeMount() {
    this.homeVisitEachData.date = !this.homeVisitEachData.date
      ? this.minDate
      : this.homeVisitEachData.date;
  },
};
</script>

<style scoped>
p {
  color: #ff6d00;
  letter-spacing: 1px;
}
.text-muted{
  color: #837e7e;
}
.header th {
  color: #fff !important;
  font-size: 15px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 15px !important;
}
.v-input--selection-controls {
  margin-top: 10px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
</style>
