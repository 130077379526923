<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead class="header">
          <tr>
            <th>No</th>
            <th>Adverse Effects</th>
            <th>Actions</th>
            <th>Actions Taken</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="se in homeVisitEachData.se_checklist" :key="se.id">
            <td>{{ se.id }}</td>
            <td>{{ showSeText(se.id) }}</td>
            <td>
              <v-radio-group
                v-model="se[Object.keys(se)[1]].val"
                hide-details
                class="mr-3 my-2"
                dense
                row
              >
                <v-radio label="Yes" value="2"></v-radio>
                <v-radio label="No" value="1"></v-radio>
              </v-radio-group>
            </td>
            <td>
              <template v-if="se[Object.keys(se)[1]].val == 2">
                <v-select
                  hide-details
                  :items="actionTakenOpt"
                  v-model="se[Object.keys(se)[1]].action_taken"
                  placeholder="choose option....."
                  outlined
                  chips
                  multiple
                  item-text="text"
                  item-value="id"
                  dense
                >
                </v-select>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row>
      <v-col lg="2">
        <label>Smear Result</label>
        <v-select @change="calculateCurrentMonth"
          :items="[
            { id: 'Negative', text: 'Negative' },
            { id: 'Scanty', text: 'Scanty' },
            { id: '1+', text: '1+' },
            { id: '2+', text: '2+' },
            { id: '3+', text: '3+' },
            { id: 'Not done', text: 'Not done' },
            { id: '', text: '' },
          ]"
          v-model="homeVisitEachData.smear_result"
          item-text="text"
          item-value="id"
          dense
          outlined 
        ></v-select>
      </v-col>
      <v-col lg="2">
        <label>Follow up month</label>
        <v-select
          :items="ordinalMonths"
          v-model="homeVisitEachData.smear_follow_up_month"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col lg="2">
        <label>Treatment Start Date</label>
        <!-- <input type="text"  name="" id=""  :value="homeVisitEachData.treatment_start_date" disabled> -->
        <!-- @onChange="calculateCurrentMonth" -->
        <date-picker
          @clearDate="homeVisitEachData.treatment_start_date = null"
          :date="receivedData" 
          v-model="homeVisitEachData.treatment_start_date" disabled
           
        />
      </v-col>
      <v-col lg="2">
        <label>Current Month</label>
        <v-text-field
          v-model="homeVisitEachData.current_month"
          readonly
          type="text"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <!-- <v-col lg="3">
        <label>Current Regimen</label>
        <v-select
          :items="[
            { id: 'Conventional', text: 'Conventional' },
            { id: 'Conventional_PAS', text: 'Conventional with PAS' },
            { id: 'Individualized_regimen', text: 'Individualized regimen' },
            { id: 'IR', text: 'IR' },
            { id: 'Pre-XDR', text: 'Pre-XDR' },
            { id: 'XDR', text: 'XDR' },
            { id: 'Poly', text: 'Poly-drug resistant' },
            { id: 'OLTR', text: 'OLTR' },
            { id: 'OSSTR', text: 'OSSTR' },
            { id: 'BPaL', text: 'BPaL' },
            { id: 'BPalM', text: 'BPalM' },
            { id: 'LR', text: 'LR' },
            { id: 'Mono', text: 'Mono-Drug Resistance' },
            { id: 'STR', text: 'STR' },
            { id: 'Individualized_MDR', text: 'Individualized MDR' },
            { id: 'Individualized_Pre-XDR', text: 'Individualized Pre-XDR' },
            { id: '', text: '' },

          ]"
          v-model="homeVisitEachData.current_regimen"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col lg="3">
        <label>Culture Result</label>
        <v-select
          :items="[
            { id: 'Negative', text: 'Negative' },
            { id: 'Positive', text: 'Positive' },
            { id: 'Not done', text: 'Not done' },
            { id: '', text: '' },
          ]"
          v-model="homeVisitEachData.culture_result"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col lg="3">
        <label>Follow up month</label>
        <v-select
          :items="ordinalMonths"
          v-model="homeVisitEachData.culture_follow_up_month"
          item-text="text"
          item-value="id"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col lg="3">
        <label>Body Weight</label>
        <v-text-field
          v-model="homeVisitEachData.body_weight"
          type="text"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <h3 class="mb-2">Verify medication and pill count checked</h3>
    <div class="wrap-container">
      <div class="col-side">
        <p>Drug Name</p>
        <p class="mt-3 mb-0">Dose</p>
      </div>
      <div class="col-main">
        <div class="slider-container">
          <div class="slider">
            <div
              v-for="(v, i) in homeVisitEachData.modifications"
              :key="i"
              class="content"
            >
              <v-select
                :items="modifications_name"
                v-model="v[`name_${i + 1}`]"
                hide-details
                item-text="text"
                item-value="id"
                dense
                outlined
              ></v-select>
             
              <v-text-field
                v-if="v[`name_${i + 1}`] === 'Other'"
                class="mt-2"
                v-model="v[`other_${i + 1}`]"
                hide-details
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                class="mt-2"
                v-model="v[`dose_${i + 1}`]"
                placeholder="dose count..."
                hide-details
                type="text"
                outlined
                dense
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-col lg="10">
      <label>Remark </label>
      <v-text-field
        v-model="homeVisitEachData.sideeffect_remark"
      ></v-text-field>
    </v-col>
    <div class="d-flex justify-end">
      <v-btn
        outlined
        class="mr-3"
        color="grey darken-4"
        dark
        @click="backInfectionList"
      >
        <v-icon left>mdi-arrow-left-thick</v-icon>
        back
      </v-btn>
      <v-btn color="purple accent-4" dark @click="finalSubmit">
        <v-icon left>mdi-chevron-right-circle-outline</v-icon>
        submit
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DatePicker from "@/components/common/DatePicker.vue";
import { modifications_name, ordinalMonths } from "../../services/options";

export default {
  components: {
    DatePicker,
  },
  props: {
    receivedData:{ type: String,},
    infectionDate:{type:String},
    homeVisitEachData: {
      type: Object,
      default: () => {
        return {
          modifications: [
            { name_1: "", other_1: "", dose_1: "" },
            { name_2: "", other_2: "", dose_2: "" },
            { name_3: "", other_3: "", dose_3: "" },
            { name_4: "", other_4: "", dose_4: "" },
            { name_5: "", other_5: "", dose_5: "" },
            { name_6: "", other_6: "", dose_6: "" },
            { name_7: "", other_7: "", dose_7: "" },
            { name_8: "", other_8: "", dose_8: "" },
          ],
          se_checklist: [
            { id: 1, question_no_1: { val: "1", action_taken: [] } },
            { id: 2, question_no_2: { val: "1", action_taken: [] } },
            { id: 3, question_no_3: { val: "1", action_taken: [] } },
            { id: 4, question_no_4: { val: "1", action_taken: [] } },
            { id: 5, question_no_5: { val: "1", action_taken: [] } },
            { id: 6, question_no_6: { val: "1", action_taken: [] } },
            { id: 7, question_no_7: { val: "1", action_taken: [] } },
            { id: 8, question_no_8: { val: "1", action_taken: [] } },
            { id: 9, question_no_9: { val: "1", action_taken: [] } },
            { id: 10, question_no_10: { val: "1", action_taken: [] } },
            { id: 11, question_no_11: { val: "1", action_taken: [] } },
            { id: 12, question_no_12: { val: "1", action_taken: [] } },
            { id: 13, question_no_13: { val: "1", action_taken: [] } },
            { id: 14, question_no_14: { val: "1", action_taken: [] } },
            { id: 15, question_no_15: { val: "1", action_taken: [] } },
            { id: 16, question_no_16: { val: "1", action_taken: [] } },
            { id: 17, question_no_17: { val: "1", action_taken: [] } },
            { id: 18, question_no_18: { val: "1", action_taken: [] } },
            { id: 19, question_no_19: { val: "1", action_taken: [] } },
            { id: 20, question_no_20: { val: "1", action_taken: [] } },
            { id: 21, question_no_21: { val: "1", action_taken: [] } },
            { id: 22, question_no_22: { val: "1", action_taken: [] } },
          ],
        };
      },
    },
  },
  data: () => ({
   
    modifications_name: modifications_name,
    ordinalMonths: ordinalMonths,
    actionTakenOpt: [
      { id: 1, text: "Health education" },
      { id: 2, text: "Counseling" },
      { id: 3, text: "Referral to health center" },
      { id: 4, text: "Immediate referral to health center" },
    ],
    seText: [
      { id: 1, text: "Headache" },
      { id: 2, text: "Dizziness" },
      { id: 3, text: "Anxiety" },
      { id: 4, text: "Depression" },
      { id: 5, text: "Optic neuritis" },
      { id: 6, text: "Tinnitus/Vertigo" },
      { id: 7, text: "Nausea/Vomiting" },
      { id: 8, text: "Gastro-Intestinal tract Intolerance" },
      { id: 9, text: "Change in bowel habit" },
      { id: 10, text: "Arrhythmia" },
      { id: 11, text: "Hepatitis" },
      { id: 12, text: "Renal Impairment" },
      { id: 13, text: "Arthralgia/Arthritis" },
      { id: 14, text: "Peripheral neuropathy" },
      { id: 15, text: "Hypokalemia" },
      { id: 16, text: "Hypothyroidism (Goiter)" },
      { id: 17, text: "Drug allergy" },
      { id: 18, text: "Change in skin color" },
      { id: 19, text: "Coagulopathy (Derangement of hemostasis)" },
      { id: 20, text: "Are the previous symptoms getting worse?" },
      {
        id: 21,
        text:
          "Are you still suffering any fever, cough and profuse sweating at night?",
      },
      { id: 22, text: "Are you still dyspneic?" },
    ],
  }),
 
  computed: {
    ...mapState("HomeVisit", ["homeVisitCollection"]),
    significanceAlert() {
      return this.homeVisitEachData.se_checklist.some((e, i) => {
        return e[`question_no_${i + 1}`].action_taken.some(
          (a) => a === 3 || a === 4
        );
      })
        ? 1
        : 0;
    },
    isModificationValidate(){
      if(
         this.homeVisitEachData.modifications.slice(0,3).every((v,i) => v[`name_${i+1}`] && v[`dose_${i+1}`])
      ){
        return false
      }
      return true
    },
    isBodyWeightValidate(){
      if(this.homeVisitEachData.body_weight){
        return false
      }
      return true
    }
  },

  methods: {
    calculateCurrentMonth() {
   console.log(Math.abs(
        new Date(this.infectionDate) - new Date(new Date(this.receivedData))
      ));
      let time = Math.abs(
        new Date(this.infectionDate) - new Date(new Date(this.receivedData))
      );
      let days = Math.ceil(time / (1000 * 60 * 60 * 24));
      this.homeVisitEachData.current_month = Math.ceil(days / 30);
    },
    backInfectionList() {
      this.$emit("backInfectionList");
    },
    showSeText(id) {
      let seListObj = this.seText.find((e) => e.id == id);
      return seListObj.text;
    },
    finalSubmit() {
      if (this.isBodyWeightValidate) {
        this.$toast.error("Body weight can't be blank.");
      } 
       else {

        const data = {
          se_checklist: this.homeVisitEachData.se_checklist,
          treatment_start_date:this.receivedData,
          // treatment_start_date:
          //   this.homeVisitEachData.treatment_start_date ?? null,
         // current_regimen: this.homeVisitEachData.current_regimen ?? null,
          current_month: this.homeVisitEachData.current_month ?? null,
          smear_result: this.homeVisitEachData.smear_result ?? null,
          sideeffect_remark:this.homeVisitEachData.sideeffect_remark ?? null,
          smear_follow_up_month:
            this.homeVisitEachData.smear_follow_up_month ?? null,
          culture_result: this.homeVisitEachData.culture_result ?? null,
          culture_follow_up_month:
            this.homeVisitEachData.culture_follow_up_month ?? null,
          body_weight: this.homeVisitEachData.body_weight ?? null,
          modifications: this.homeVisitEachData.modifications,
          is_alert: this.significanceAlert,
        };
        console.log(data);
        this.$store.dispatch("HomeVisit/setSeFormData", data);
        this.$emit("finalSubmit");
      }
    },
  },
};
</script>

<style scoped>
.wrap-container {
  display: flex;
  background-color: #eee3fc;
  padding: 10px 5px;
  border-radius: 4px;
}

.wrap-container .col-main {
  flex: 1 1 100%;
  width: 0;
}

.wrap-container .col-side {
  flex: 0 0 8em;
}

.slider-container {
  overflow-x: scroll;
}

.slider-container::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

.slider-container::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.slider-container::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(179, 179, 179, 0.8);
}

.slider-container .slider {
  display: flex;
  width: 100em;
  margin-bottom: 10px;
}

.slider .content {
  width: 15em;
  margin-left: 10px;
}

.header th {
  color: #fff !important;
  font-size: 15px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 15px !important;
}

.v-input--selection-controls {
  margin-top: 10px !important;
}
</style>
