<template>
  <spinner-container :loading="localLoading">
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="12">
          <v-card class="card--shadow">
            <v-stepper class="card--shadow" v-model="stepper">
              <v-stepper-header class="card--shadow">
                <v-stepper-step
                  color="purple accent-4"
                  :complete="stepper > 1"
                  step="1"
                >
                  Infection Control checklist
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="purple accent-4" step="2">
                  Side effect checklist
                </v-stepper-step>

                <v-divider></v-divider>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <infection-form-list
                    :month_year="month_year"
                    @nextSideEffect="stepper = 2"
                    :homeVisitEachData="homeVisitEachData"
                  />
                </v-stepper-content>

                <v-stepper-content step="2">
                  <side-effect-form-list
                    :homeVisitEachData="homeVisitEachData"
                    @backInfectionList="stepper = 1"
                    @finalSubmit="updateHomeVisit"
                  />
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
          <feedback
            :adminId="admin.id"
            :feedbacks="feedbacks"
            @createComment="createComment"
            @deleteComment="deleteComment"
          />
        </v-col>
      </v-row>
    </v-container>
  </spinner-container>
</template>

<script>
import BackBtn from "@/components/navigation/BackBtn.vue";
import Feedback from "@/components/feedback/Feedback";
import InfectionFormList from "@/components/home-visit/InfectionFormList";
import SideEffectFormList from "@/components/home-visit/SideEffectFormList";
import SpinnerContainer from "@/components/container/SpinnerContainer";

import { mapState } from "vuex";

export default {
  components: {
    BackBtn,
    InfectionFormList,
    SideEffectFormList,
    SpinnerContainer,
    Feedback,
  },
  data: () => ({
    stepper: 1,
  }),
  computed: {
    ...mapState("HomeVisit", ["homeVisitEachData", "homeVisitCollection"]),
    ...mapState("Loading", ["localLoading"]),
    ...mapState("Feedback", ["feedbacks"]),
    ...mapState("Admin", ["admin"]),
    month_year() {
      const month_year = {
        month: this.$route.params.month,
        year: this.$route.params.year,
      };
      return month_year;
    },
  },
  methods: {
    createComment(comment) {
      const newComment = {
        item_id: this.$route.params.id,
        item_type: "HOMEVISIT",
        author_id: this.admin.id,
        author_type: "PA",
        body: comment,
        township_id:this.admin.township_id
      };
      this.$store.dispatch("Feedback/create", newComment);
    },
    deleteComment(id) {
      this.$store.dispatch("Feedback/delete", id);
    },
    updateHomeVisit() {
      this.$store
        .dispatch("HomeVisit/update", this.homeVisitCollection)
        .then(() => {
          this.$router.push(
            `/home-visit/activity/${this.$route.params.month}/${this.$route.params.year}`
          );
        });
    },
  },

  created() {
    this.$store.dispatch("Loading/changeLocalLoading");
    this.$store.dispatch("Feedback/getAll", {
      item_id: this.$route.params.id,
      item_type: "HOMEVISIT",
    });
    this.$store
      .dispatch("HomeVisit/getEach", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("Loading/changeLocalLoading");
      });
  },
};
</script>
